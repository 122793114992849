import {useCallback} from "react";
import * as React from "react";
import cx from "classnames";
import Media from "../Common/Media";
import Button from "../UI/Button";
import * as componentsStyles from "./article-components.module.css";

export const Paragraph = ({text}) => {
    return <p dangerouslySetInnerHTML={{__html: text}} className={componentsStyles.articleComponents__paragraph} />;
};

export const Header = (() => {
    return ({text, level, idx}) => {
        if (level < 3) {
            return (
                <h2
                    className={componentsStyles.articleComponents__header}
                    id={`header-${idx}`}
                    dangerouslySetInnerHTML={{__html: text}}
                />
            );
        }

        return (
            <h3
                className={`${componentsStyles.articleComponents__header} ${componentsStyles.articleComponents__header_small}`}
                dangerouslySetInnerHTML={{__html: text}}
            />
        );
    };
})();

export const List = ({style, items}) => {
    const getItems = () => {
        return (
            <>
                {items.map((item, idx) => {
                    return (
                        <li key={idx} className={componentsStyles.articleComponents__listItem}>
                            <span dangerouslySetInnerHTML={{__html: item}} />
                        </li>
                    );
                })}
            </>
        );
    };

    return style === "unordered" ? (
        <ul className={componentsStyles.articleComponents__list}>{getItems()}</ul>
    ) : (
        <ol
            className={`${componentsStyles.articleComponents__list} ${componentsStyles.articleComponents__list_ordered}`}
        >
            {getItems()}
        </ol>
    );
};

export const Quote = ({text, caption, note, localFile, photo, type}) => {
    const getDimensions = (file, fileWidth, fileHeight) => {
        if (file?.mime !== "image/svg+xml") {
            return {
                width: file?.childImageSharp?.gatsbyImageData?.width || file?.width || fileWidth,
                height: file?.childImageSharp?.gatsbyImageData?.height || file?.height || fileHeight,
            };
        }

        const viewBox = (/viewBox="([^"]+)"/.exec(file?.svg?.content) || "")[1];
        // eslint-disable-next-line no-unused-vars
        const [_, __, svgWidth, svgHeight] = viewBox.split(" ");

        return {width: svgWidth, height: svgHeight};
    };

    const getMedia = (file, {width: fileWidth, height: fileHeight, mime}) => {
        const {width, height} = getDimensions(file, fileWidth, fileHeight);

        return (
            <div>
                <Media
                    className={componentsStyles.articleComponents__quotePhoto}
                    media={{content: {localFile: file, width, height, mime}, youtubeVideoURL: ""}}
                />
            </div>
        );
    };

    return (
        <blockquote
            className={cx(componentsStyles.articleComponents__quote, {
                [componentsStyles.articleComponents__quote_quotes]: type === "quotes",
                [componentsStyles.articleComponents__quote_background]: type === "background",
            })}
        >
            {text && (
                <p dangerouslySetInnerHTML={{__html: text}} className={componentsStyles.articleComponents__quoteText} />
            )}
            <div className={componentsStyles.articleComponents__quoteCaption}>
                {photo && getMedia(localFile, photo)}
                <cite>
                    {caption && (
                        <p
                            dangerouslySetInnerHTML={{__html: caption}}
                            className={cx(componentsStyles.articleComponents__quoteCite, {
                                [componentsStyles.articleComponents__quoteCite_withImage]: !!photo,
                            })}
                        />
                    )}
                    {note && (
                        <p
                            dangerouslySetInnerHTML={{__html: note}}
                            className={componentsStyles.articleComponents__quoteNote}
                        />
                    )}
                </cite>
            </div>
        </blockquote>
    );
};

export const Image = ({localFile, caption, file: {width: fileWidth, height: fileHeight, mime}}) => {
    if (!localFile) {
        return null;
    }

    const getDimensions = () => {
        if (localFile.mime !== "image/svg+xml") {
            return {
                width: localFile.childImageSharp?.gatsbyImageData?.width || localFile.width || fileWidth,
                height: localFile.childImageSharp?.gatsbyImageData?.height || localFile.height || fileHeight,
            };
        }

        const viewBox = (/viewBox="([^"]+)"/.exec(localFile.svg?.content) || "")[1];
        // eslint-disable-next-line no-unused-vars
        const [_, __, svgWidth, svgHeight] = viewBox.split(" ");

        return {width: svgWidth, height: svgHeight};
    };

    const getMedia = () => {
        const {width, height} = getDimensions();
        const aspectRatio = `${(height / width) * 100}%`;

        return (
            <div
                style={{"--aspect-ratio": aspectRatio, maxWidth: `${width}px`, maxHeight: `${height}px`}}
                className={componentsStyles.articleComponents__imageWrapper}
            >
                <Media
                    className={componentsStyles.articleComponents__imageContent}
                    media={{content: {localFile, width, height, mime}, youtubeVideoURL: ""}}
                    imageProps={{aspectratio: width / height}}
                />
            </div>
        );
    };

    return (
        <figure className={componentsStyles.articleComponents__image}>
            {getMedia()}
            <figcaption
                dangerouslySetInnerHTML={{__html: caption}}
                className={componentsStyles.articleComponents__imageCaption}
            />
        </figure>
    );
};

export const ArticleButton = ({text, link}) => {
    return (
        <div className={componentsStyles.articleComponents__button}>
            <Button variant="primary" isLink href={link} data-button>
                {text}
            </Button>
        </div>
    );
};

export const Embed = ({frame, caption}) => {
    return (
        <figure className={componentsStyles.articleComponents__embed}>
            <div
                className={componentsStyles.articleComponents__embedContent}
                dangerouslySetInnerHTML={{__html: frame}}
            />
            <figcaption
                dangerouslySetInnerHTML={{__html: caption}}
                className={componentsStyles.articleComponents__embedCaption}
            />
        </figure>
    );
};

export const Counters = ({counters}) => {
    return (
        <div>
            {counters.map((row, rowIdx) => {
                return (
                    <div
                        key={rowIdx}
                        className={`${componentsStyles.articleComponents__countersRow} ${
                            componentsStyles[`articleComponents__countersRow_${row.length}`]
                        }`}
                    >
                        {row.map(({title, text}, counterIdx) => {
                            return (
                                <div key={counterIdx}>
                                    <h4 className={componentsStyles.articleComponents__countersTitle}>{title}</h4>
                                    <p className={componentsStyles.articleComponents__countersText}>{text}</p>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export const Contents = ({title, links}) => {
    const scrollHandler = useCallback((e, anchor) => {
        const anchorElement = document.querySelector(`#${anchor}`);
        if (!anchorElement) {
            return;
        }

        e.preventDefault();

        window.scrollTo({top: anchorElement.offsetTop - 100, behavior: "smooth"});

        const url = new URL(window.location);
        url.hash = anchor;

        window.history.pushState({}, "", url);
    }, []);

    return (
        <div className={componentsStyles.articleComponents__contents}>
            <h2 className={componentsStyles.articleComponents__contentsTitle}>{title}</h2>
            <ul className={componentsStyles.articleComponents__contentsList}>
                {links.map(({text, anchor}) => {
                    return (
                        <li key={anchor} className={componentsStyles.articleComponents__contentsListItem}>
                            <a
                                href={`#${anchor}`}
                                onClick={(event) => scrollHandler(event, anchor)}
                                className={componentsStyles.articleComponents__contentsLink}
                                dangerouslySetInnerHTML={{__html: text}}
                                aria-label={`Перейти к заголовку "${text}"`}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const Table = ({content, withHeadings}) => {
    return (
        <div className={componentsStyles.articleComponents__table}>
            {content.map((row, rowIdx) => {
                const isHeadings = withHeadings && rowIdx === 0;

                return (
                    <div key={rowIdx} className={componentsStyles.articleComponents__tableRow}>
                        {row.map((cellContent, colIdx) => {
                            return (
                                <div
                                    key={colIdx}
                                    className={`${componentsStyles.articleComponents__tableCell} ${
                                        isHeadings ? componentsStyles.articleComponents__tableCell_heading : ""
                                    }`}
                                >
                                    <p dangerouslySetInnerHTML={{__html: cellContent}} />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export const Personality = ({description, localFile, photo}) => {
    const data = {
        width: localFile?.childImageSharp?.gatsbyImageData?.width || photo?.width,
        height: localFile?.childImageSharp?.gatsbyImageData?.height || photo?.height,
        mime: localFile?.mime || photo?.mime,
    };

    return (
        <div className={componentsStyles.articleComponents__personality}>
            <Media
                className={componentsStyles.articleComponents__personalityImage}
                media={{
                    content: {localFile, width: data.width, height: data.height, mime: data.mime},
                    youtubeVideoURL: "",
                }}
            />
            <p
                dangerouslySetInnerHTML={{__html: description}}
                className={componentsStyles.articleComponents__personalityDesc}
            />
        </div>
    );
};

export const Chess = ({content, image, localFile, reversed}) => {
    const data = {
        width: localFile?.childImageSharp?.gatsbyImageData?.width || image?.width,
        height: localFile?.childImageSharp?.gatsbyImageData?.height || image?.height,
        mime: localFile?.mime || image?.mime,
    };

    return (
        <div
            className={cx(componentsStyles.articleComponents__chess, {
                [componentsStyles.articleComponents__chess_reversed]: reversed,
            })}
        >
            <Media
                media={{
                    content: {localFile, width: data.width, height: data.height, mime: data.mime},
                    youtubeVideoURL: "",
                }}
            />
            <div className={componentsStyles.articleComponents__chessColumn}>
                {content.map(({content: blockContent, type}, idx) => {
                    return (
                        <p
                            className={cx(componentsStyles.articleComponents__chessText, {
                                [componentsStyles.articleComponents__chessText_p]: type === "paragraph",
                                [componentsStyles.articleComponents__chessText_ol]: type === "ol_item",
                                [componentsStyles.articleComponents__chessText_ul]: type === "ul_item",
                            })}
                            key={`right${idx}`}
                            dangerouslySetInnerHTML={{__html: blockContent}}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export const Columns = ({left, right}) => {
    return (
        <div className={componentsStyles.articleComponents__columns}>
            <div className={componentsStyles.articleComponents__columnsColumn}>
                {left.map(({content, type}, idx) => {
                    return (
                        <p
                            className={cx(componentsStyles.articleComponents__columnsText, {
                                [componentsStyles.articleComponents__columnsText_p]: type === "paragraph",
                                [componentsStyles.articleComponents__columnsText_ol]: type === "ol_item",
                                [componentsStyles.articleComponents__columnsText_ul]: type === "ul_item",
                            })}
                            key={`left_${idx}`}
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                    );
                })}
            </div>
            <div className={componentsStyles.articleComponents__columnsColumn}>
                {right.map(({content, type}, idx) => {
                    return (
                        <p
                            className={cx(componentsStyles.articleComponents__columnsText, {
                                [componentsStyles.articleComponents__columnsText_p]: type === "paragraph",
                                [componentsStyles.articleComponents__columnsText_ol]: type === "ol_item",
                                [componentsStyles.articleComponents__columnsText_ul]: type === "ul_item",
                            })}
                            key={`right${idx}`}
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export const Features = ({elements, count}) => {
    return (
        <div
            className={cx(componentsStyles.articleComponents__features, {
                [componentsStyles.articleComponents__features_3]: count === 3,
            })}
        >
            {elements.map(({title, text, icon}, idx) => {
                const data = {
                    width: icon?.childImageSharp?.gatsbyImageData?.width,
                    height: icon?.childImageSharp?.gatsbyImageData?.height,
                    mime: icon?.mime,
                };

                return (
                    <div key={idx} className={componentsStyles.articleComponents__featuresElement}>
                        {icon && (
                            <Media
                                className={componentsStyles.articleComponents__featuresImage}
                                media={{
                                    content: {localFile: icon, width: data.width, height: data.height, mime: data.mime},
                                    youtubeVideoURL: "",
                                }}
                            />
                        )}
                        <div>
                            <p
                                className={componentsStyles.articleComponents__featuresTitle}
                                dangerouslySetInnerHTML={{__html: title}}
                            />
                            <p
                                className={componentsStyles.articleComponents__featuresText}
                                dangerouslySetInnerHTML={{__html: text}}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
