// extracted by mini-css-extract-plugin
export var articleComponents__paragraph = "article-components-module--article-components__paragraph--QfY6Y";
export var articleComponents__personality = "article-components-module--article-components__personality--SulzC";
export var articleComponents__header = "article-components-module--article-components__header--gY--Z";
export var articleComponents__header_small = "article-components-module--article-components__header_small--zLRpC";
export var articleComponents__list = "article-components-module--article-components__list--mGdCl";
export var articleComponents__list_ordered = "article-components-module--article-components__list_ordered--SbXQg";
export var articleComponents__listItem = "article-components-module--article-components__list-item--7MSFJ";
export var articleComponents__quote = "article-components-module--article-components__quote--PL62P";
export var articleComponents__quoteText = "article-components-module--article-components__quote-text--ibF4I";
export var articleComponents__quoteCite = "article-components-module--article-components__quote-cite--vvbO2";
export var articleComponents__quoteNote = "article-components-module--article-components__quote-note--SR15J";
export var articleComponents__quoteCaption = "article-components-module--article-components__quote-caption--P6aGf";
export var articleComponents__quotePhoto = "article-components-module--article-components__quote-photo--v9Sog";
export var articleComponents__quote_quotes = "article-components-module--article-components__quote_quotes--ShmIv";
export var articleComponents__quote_background = "article-components-module--article-components__quote_background--Lzhiv";
export var articleComponents__quoteCite_withImage = "article-components-module--article-components__quote-cite_withImage--2-sLI";
export var articleComponents__button = "article-components-module--article-components__button--jR1Yl";
export var articleComponents__image = "article-components-module--article-components__image--cT4g4";
export var articleComponents__imageCaption = "article-components-module--article-components__image-caption--JA6IB";
export var articleComponents__imageWrapper = "article-components-module--article-components__image-wrapper--nDGkj";
export var articleComponents__imageContent = "article-components-module--article-components__image-content--4-te6";
export var articleComponents__embed = "article-components-module--article-components__embed--OacrI";
export var articleComponents__embedContent = "article-components-module--article-components__embed-content--7iKvU";
export var articleComponents__embedCaption = "article-components-module--article-components__embed-caption--xqmRA";
export var articleComponents__countersRow = "article-components-module--article-components__counters-row--70XKm";
export var articleComponents__countersRow_1 = "article-components-module--article-components__counters-row_1--eNxE-";
export var articleComponents__countersRow_2 = "article-components-module--article-components__counters-row_2--dp9fa";
export var articleComponents__countersRow_3 = "article-components-module--article-components__counters-row_3--8v-3A";
export var articleComponents__countersRow_4 = "article-components-module--article-components__counters-row_4--Lje5Z";
export var articleComponents__countersTitle = "article-components-module--article-components__counters-title--oVkkp";
export var articleComponents__countersText = "article-components-module--article-components__counters-text--RXcu4";
export var articleComponents__table = "article-components-module--article-components__table--5PSMJ";
export var articleComponents__tableRow = "article-components-module--article-components__table-row--Yu+dE";
export var articleComponents__tableCell = "article-components-module--article-components__table-cell--6SwQ3";
export var articleComponents__tableCell_heading = "article-components-module--article-components__table-cell_heading--RPvZa";
export var articleComponents__contents = "article-components-module--article-components__contents--oPq5r";
export var articleComponents__contentsTitle = "article-components-module--article-components__contents-title--J0aPN";
export var articleComponents__contentsList = "article-components-module--article-components__contents-list--m-IDp";
export var articleComponents__contentsListItem = "article-components-module--article-components__contents-list-item--y7y2j";
export var articleComponents__contentsLink = "article-components-module--article-components__contents-link--Cz9Jf";
export var articleComponents__personalityImage = "article-components-module--article-components__personality-image--xGDzt";
export var articleComponents__personalityDesc = "article-components-module--article-components__personality-desc--nKhQE";
export var articleComponents__chess = "article-components-module--article-components__chess--BpoUx";
export var articleComponents__chess_reversed = "article-components-module--article-components__chess_reversed--wRIas";
export var articleComponents__chessColumn = "article-components-module--article-components__chess-column--ofbgT";
export var articleComponents__chessText = "article-components-module--article-components__chess-text--EmZ6r";
export var articleComponents__chessText_p = "article-components-module--article-components__chess-text_p--lBRop";
export var articleComponents__chessText_ol = "article-components-module--article-components__chess-text_ol--NmJxn";
export var articleComponents__chessText_ul = "article-components-module--article-components__chess-text_ul--Amhll";
export var articleComponents__columns = "article-components-module--article-components__columns--FH6QK";
export var articleComponents__columnsColumn = "article-components-module--article-components__columns-column--w-gRr";
export var articleComponents__columnsText = "article-components-module--article-components__columns-text--dKbPU";
export var articleComponents__columnsText_p = "article-components-module--article-components__columns-text_p--C7Egz";
export var articleComponents__columnsText_ol = "article-components-module--article-components__columns-text_ol--ArWwZ";
export var articleComponents__columnsText_ul = "article-components-module--article-components__columns-text_ul--yJoSl";
export var articleComponents__features = "article-components-module--article-components__features--JNu3i";
export var articleComponents__features_3 = "article-components-module--article-components__features_3--Av4hq";
export var articleComponents__featuresElement = "article-components-module--article-components__features-element--t5wtS";
export var articleComponents__featuresImage = "article-components-module--article-components__features-image--Rj-Lj";
export var articleComponents__featuresTitle = "article-components-module--article-components__features-title--eWDxg";
export var articleComponents__featuresText = "article-components-module--article-components__features-text--IHmc0";